<template>
  <div class="discover-wrapper">
    <div class="top-wrapper">
      <img src="../../assets/images/discover_title1.png" alt="" />
    </div>
    <div class="card-wrapper">
      <div class="card" v-for="(item, index) of 1" :key="index">
        <div class="img-wrapper imgscale">
          <el-image
            style="width: 100%; height: 100%"
            :src="require('../../assets/images/pc@2x.png')"
            fit="cover"
          >
          </el-image>
        </div>
        <p class="title">友谊玖玖</p>
        <p class="content">
          宝船以海上丝绸之路郑和下西洋的“宝船”为原型进行了创作。郑和宝船供郑和船队的指挥人员、使团人员以及外国使节乘坐。同时，用它来装运宝物，有明朝皇帝赏赐给西洋各国的礼品的礼品、物品，也有西洋进贡的贡品。为此，称为“宝船”，意为“运宝之船”
        </p>
      </div>
      
    </div>
    <div class="img-title">
      <img src="../../assets/images/discover_title2.png" alt="" />
    </div>
    <div class="banner-wrapper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            style="width: 985px"
            v-for="(item, index) of dsList"
            :key="index"
          >
            <div class="banner-card">
              <div class="img-wrapper">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.avatar"
                  fit="cover"
                >
                </el-image>
              </div>
              <div class="right">
                <p class="name">{{item.name}}</p>
                <div class="title">
                  <p>{{item.desc}}</p>
                </div>
                <!-- <div class="pro-list">
                  <div class="wrapper">
                    <div class="pro-img">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="require('../../assets/images/index_banner.png')"
                        fit="cover"
                      >
                      </el-image>
                    </div>
                    <div class="pro-img">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="require('../../assets/images/index_banner.png')"
                        fit="cover"
                      >
                      </el-image>
                    </div>
                    <div class="pro-img">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="require('../../assets/images/index_banner.png')"
                        fit="cover"
                      >
                      </el-image>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <!-- <div class="banner-card" style="display: none">
        <div class="img-wrapper">
          <el-image
            style="width: 100%; height: 100%"
            :src="require('../../assets/images/index_banner.png')"
            fit="cover"
          >
          </el-image>
        </div>
        <div class="right">
          <p class="name">霍铁辉</p>
          <div class="title">
            <p>中国工艺美术大师</p>
            <p>北京市特级工艺美术大师</p>
            <p>中国非物质文化遗产代表性人物</p>
          </div>
          <div class="pro-list">
            <div class="wrapper">
              <div class="pro-img">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('../../assets/images/index_banner.png')"
                  fit="cover"
                >
                </el-image>
              </div>
              <div class="pro-img">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('../../assets/images/index_banner.png')"
                  fit="cover"
                >
                </el-image>
              </div>
              <div class="pro-img">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require('../../assets/images/index_banner.png')"
                  fit="cover"
                >
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import swiper from "swiper/js/swiper.min";
import "swiper/css/swiper.min.css";
export default {
  name: "Discover",
  data() {
    return {
      swiperList: [
        { icon: require("../../assets/images/index_banner.png") },
        { icon: require("../../assets/images/index_banner.png") },
        { icon: require("../../assets/images/index_banner.png") },
        { icon: require("../../assets/images/index_banner.png") },
      ],
      dsList: [
        {
          name: '钟连盛',
          desc: '中国工艺美术大师、国家级非物质文化遗产项目景泰蓝技艺代表性传承人、北京市珐琅厂总工艺师，享受国务院特殊津贴。曾参与创作《盛世欢歌》、《四面方尊》、《和平欢歌》等多件国礼，被赠与联合国、世界经济论坛、93大阅兵天安门城楼观礼的30国元首等。在北京 APEC会议、“一带一路”国际合作高峰论坛、特朗普访华3次国事外交活动中，展示景泰蓝制作技艺，被誉为“中国景泰蓝外交第一人”。',
          avatar: require("../../assets/ds/ds-htj.png")
        },
        {
          name: '衣福成',
          desc: '国家级非物质文化遗产代表性项目北京市级传承人、北京市工艺美术大师、北京市珐琅厂董事长。从事景泰蓝工艺近40年，指导、参与的作品屡次荣获国家级、部级、市级金奖，代表作品《歪嘴花鸟葫芦瓶》、《繁花似锦瓶》等。2020年末，衣福成被认定为2020年北京老字号工匠，是传统“工匠精神”的代表。',
          avatar: require("../../assets/ds/ds-yfc.png")
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper();
    });
  },
  methods: {
    initSwiper() {
      new swiper(".swiper-container", {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: -800,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.discover-wrapper {
  width: 100%;
  height: auto;
  background-color: #fff;
  overflow: hidden;
  .banner-wrapper {
    width: 1200px;
    margin: 0 auto 36px;
    .swiper-container {
      width: 1000px;
      height: 100%;
      padding: 8px 0;
      margin: 0 auto;
      overflow: unset;
      --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
      --swiper-navigation-size: 30px; /* 设置按钮大小 */
      .swiper-slide {
        width: 1000px !important;
        height: 100%;
        transition: 300ms;
        transform: scale(0.7);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        overflow: hidden;
      }
      .swiper-slide-active,
      .swiper-slide-duplicate-active {
        transform: scale(1);
        opacity: 1;
      }
    }
    .banner-card {
      // width: 1000px;
      // height: 433px;
      // padding: 40px;
      background-color: #fff;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .img-wrapper {
        width: 400px;
        height: 353px;
        overflow: hidden;
        margin-right: 50px;
      }
      .right {
        // .pro-list {
        //   width: 510px;
        //   height: 130px;
        //   overflow-x: auto;
        //   overflow-y: hidden;
        //   .wrapper {
        //     min-width: 510px;
        //     max-width: none;
        //     height: 128px;
        //     display: inline-flex;
        //     &::after {
        //       content: "";
        //       height: 0;
        //       line-height: 0;
        //       display: block;
        //       visibility: hidden;
        //       clear: both;
        //     }
        //   }
        //   .pro-img {
        //     width: 150px;
        //     height: 128px;
        //     border-radius: 8px;
        //     overflow: hidden;
        //     margin-right: 30px;
        //     float: left;
        //     &:last-child {
        //       margin-right: 0;
        //     }
        //   }
        // }
        .title {
          font-size: 14px;
          color: #000000;
          font-weight: 400;
          // height: 80px;
          // overflow-y: auto;
          margin-bottom: 40px;
          max-width: 485px;
          p {
          }
        }
        .name {
          font-size: 24px;
          color: #000000;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }
  .img-title {
    width: 373px;
    height: 60px;
    margin: 0 auto 86px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .card-wrapper {
    width: 1200px;
    height: auto;
    margin: -110px auto 74px;
    display: flex;
    justify-content: center;
    .card {
      width: 330px;
      height: auto;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
      .title {
        font-size: 20px;
        color: #23262f;
        font-weight: 500;
        margin: 20px 0 10px;
      }
      .content {
        font-size: 14px;
        color: #909399;
        font-weight: 300;
      }
      .img-wrapper {
        width: 330px;
        height: 400px;
        border-radius: 12px;
        overflow: hidden;
      }
    }
  }
  .top-wrapper {
    width: 100%;
    height: 300px;
    background-image: url("../../assets/images/discover_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 71px;
    img {
      width: 373px;
      display: block;
      margin: 0 auto;
    }
  }
  /deep/.swiper-slide{
    opacity: 0!important;
  }
  /deep/.swiper-slide-active{
    z-index: 10;
    opacity: 1!important;
  }
  /deep/.swiper-slide-prev{
    // transform: scale(0.8) translateX(850px)!important;
    opacity: 0.2!important;
  }
  /deep/.swiper-slide-next{
    // transform: scale(0.8) translateX(-850px)!important;
    opacity: 0.2!important;
  }
  .swiper-button-prev{
    left: -70px;
    width: 50px;
    height: 50px;
    background: url('../../assets/images/prev-btn.png') no-repeat;
    background-size: contain;
    &::after{
      display: none;
    }
  }
  .swiper-button-next{
    right: -70px;
    width: 50px;
    height: 50px;
    background: url('../../assets/images/next-btn.png') no-repeat;
    background-size: contain;
    &::after{
      display: none;
    }
  }
}
.imgscale{
  transition: all 0.3s;
  &:hover{
    transform: scale(1.05);
  }
}
</style>